import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import RightSide from '../../components/rightside-general'

import BG from '../../images/awards-bg.jpg'

class FirmProfile extends React.Component {
	render() {
		const siteTitle = 'Firm Profile'
		const siteDescription = this.props.data.site.siteMetadata.siteDescription

		return (
			<Layout title={siteTitle} location={this.props.location}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div className="col-md-8 left-list">
							<div id="contentgen">
								<h3 className="mb-3">Firm Profile</h3>
								<p>
									Our business is people focused because we believe our approach
									to clients’ financial needs should be as unique as they are.
									Through understanding how our clients conduct their business,
									we can offer straightforward advice and guidance.
								</p>
								<p>
									At WSC Group, the skills, knowledge and expertise of our team
									have been developed through practical and continuous
									professional development. Therefore, you can be assured that
									your advice will be prepared by a qualified and experienced
									professional. Our staff work with you to understand your
									business needs and the issues you may be facing to provide the
									best possible financial advice in any situation.
								</p>
								<p>
									We work hard on striving to achieve a culture of excellence
									and service with the following commitments being a sign of our
									dedication to customer satisfaction.
								</p>
								<h4>These include:</h4>
								<ul>
									<li>
										Our commitment to responding to your requests in a timely
										and efficient manner
									</li>
									<li>
										Our commitment to communicating with our clients to
										facilitate their customer satisfaction
									</li>
									<li>
										Our commitment to provide a value for money service where
										the value added services provided to our clients far exceeds
										the cost of those services.
									</li>
								</ul>
								<p>
									Whether you are commencing a business, need assistance in
									restructuring, expanding your business, or simply want to
									better manage your finances, we can provide a tailor-made
									solution to meet your requirements.
								</p>
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default FirmProfile

export const FirmProfilePageQuery = graphql`
	query FirmProfilePageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
